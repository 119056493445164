import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root"
})
export class AppContextService {
  context = {
    vollmacht: {
      anrede: null,
      anzahl_erinnerungen: null,
      buro_id: null,
      c_o: null,
      created_at: null,
      email: null,
      einwilligungen: null,
      geburtsdatum: null,
      id: null,
      key: null,
      key_valid_until: null,
      kundennummer: null,
      land: null,
      mobil: null,
      festnetz: null,
      name: null,
      ort: null,
      plz: null,
      strasse: null,
      titel: null,
      ts_abgelehnt: null,
      ts_finished: null,
      ts_lastlogin: null,
      ts_letzte_erinnerung: null,
      ts_letzter_export: null,
      unterschrift: null,
      unterschrift_vm: null,
      updated_at: null,
      user_id: null,
      vollmachten: {
        maklervertrag: null,
        vertragsbestand: {
          vertraege: []
        },
        datenschutz: {
          dkomm_einwilligungen: {},
          kommunikation: {
            brief: true,
            telefon: false,
            fax: false,
            email: false,
            whatsapp: false
          },
          beratung_weitere_produkte: false,
          datenweitergabe_an_personen: null
        },
        frei: {
          title: null,
          description: null,
          template: null,
          attachment: null
        },
        ab: {
          halter: null,
          diagnosefindung: null,
          datenverarbeitung: null,
          rufname: null,
          geschlecht: null,
          rasse: null,
          tierart: null,
          farbe: null,
          geburtsdatum: null,
          kastriert: null,
          nummer: null,
          lebensmittelgewinnung: null,
          krankenversichert: null,
          gesellschaft: null,
          vertragsnummer: null,
          haustierarzt: null,
          ueberweisung: null,
          ueberweisung_durch: null,
          vereinbarter_termin: null
        }
      },
      vollmachtstypen: null,
      vorname: null
    },
    customizing: {
      orga_key: ''
    },
    pageconfig: {},
    isPlatformDesktop: false,
    isPlatformMobile: false,
    isLoggedIn: false,
    isStammdatenVisited: false,
    isUmfangVisited: false,
    isBestandsaufnahmeVisited: false,
    isBestandsaufnahmeVFVisited: false,
    isAufnahmescheinVisited: false,
    isDatenschutzVisited: false,
    isDatenschutzVFVisited: false,
    isFreiVisited: false,
    isZusammenfassungVisited: false,
    isEinwilligungVisited: false,
    isUnterschriftVisited: false,
    isUnterschriftVFVisited: false,
    privatDisplayString: "",
    berufDisplayString: "",
    investmentDisplayString: "",
    kommunikationDisplayString: "",
    einwilligungDisplayString: ""
  };

  cfgBottomDrawer = {
    start: {
      show: false,
      index: 0
    },
    stammdaten: {
      show: false,
      index: 0
    },
    umfang: {
      show: false,
      index: 0
    },
    bestandsaufnahme: {
      show: false,
      index: 0
    },
    bestandsaufnahmevf: {
      show: false,
      index: 0
    },
    aufnahmeschein: {
      show: false,
      index: 0
    },
    datenschutz: {
      show: false,
      index: 0
    },
    datenschutzvf: {
      show: false,
      index: 0
    },
    frei: {
      show: false,
      index: 0
    },
    zusammenfassung: {
      show: false,
      index: 0
    },
    einwilligung: {
      show: false,
      index: 0
    },
    unterschrift: {
      show: false,
      index: 0
    },
    unterschriftvf: {
      show: false,
      index: 0
    }
  };

  getContentCSS(obj) {
    if (this.context.customizing.orga_key) {
      if (this.context.customizing.orga_key == 'Tierklinik Elversberg') return "content-tierklinik";
      if (this.context.customizing.orga_key == 'versifair')  return "content-versifair";
      else return "content-standard";
    } else return "";
  }

  initEinwilligungen() {
    const vollmachtTypen = this.context.vollmacht.vollmachtstypen;
    var mapping: { [key: string]: string } = {
      mvertrag: "Maklervertrag",
      mvollmacht: "Maklervollmacht",
      vbestand: "Vertragsbestand",
      vbestandvf: "Vertragsbestand",
      dkomm: "Datenschutz & Kommunikation",
      dkommvf: "Datenschutz & Kommunikation",
      einfo: "Erstinformation",
      agb: "Allgemeine Geschäftsbedingungen",
      wbelehrung: "Widerrufsbelehrung",
      ab: "Aufnahmeschein",
      dkomm_simple: "Datenschutz (einfach)"
    };
    if (vollmachtTypen.indexOf("frei") > -1) mapping["frei"] = this.context.vollmacht.vollmachten.frei.title;
    this.context.vollmacht.einwilligungen = Array();
    for (let key in vollmachtTypen) {
      this.context.vollmacht.einwilligungen.push({
        key: vollmachtTypen[key],
        label: mapping[vollmachtTypen[key]],
        value: false
      });
    }
  }

  initBottomDrawer() {
    const vollmachtTypen = this.context.vollmacht.vollmachtstypen;
    var pages = Array("start", "stammdaten", "einwilligung");

    if (vollmachtTypen.indexOf("mvertrag") > -1) pages = pages.concat(["umfang", "zusammenfassung"]);
    if (vollmachtTypen.indexOf("mvollmacht") > -1) pages = pages.concat(["zusammenfassung"]);

    if (vollmachtTypen.indexOf("vbestand") > -1) pages = pages.concat(["bestandsaufnahme", "zusammenfassung"]);

    if (vollmachtTypen.indexOf("dkomm") > -1) pages = pages.concat(["datenschutz", "zusammenfassung"]);
    if (vollmachtTypen.indexOf("ab") > -1) pages = pages.concat(["aufnahmeschein", "zusammenfassung"]);

    if (vollmachtTypen.indexOf("vbestandvf") > -1) pages = pages.concat(["bestandsaufnahmevf", "zusammenfassung"]);
    if (vollmachtTypen.indexOf("dkommvf") > -1) pages = pages.concat(["datenschutzvf", "zusammenfassung"]);
    if (vollmachtTypen.indexOf("dkomm_simple") > -1) pages = pages.concat(["zusammenfassung"]);

    if (vollmachtTypen.indexOf("frei") > -1) pages = pages.concat(["frei"]);

    if (pages.indexOf("bestandsaufnahmevf") > -1 || pages.indexOf("datenschutzvf") > -1) pages = pages.concat(["unterschriftvf"]);
    else pages = pages.concat(["unterschrift"]);

    var index = 0;
    for (let key in this.cfgBottomDrawer) {
      if (pages.indexOf(key) > -1) {
        this.cfgBottomDrawer[key].show = true;
        this.cfgBottomDrawer[key].index = index;
        index++;
      }
    }
  }

  hasInvestment() {
    return Object.keys(this.context.vollmacht.vollmachten.maklervertrag.beratungsumfang_investment).length > 0;
  }
  hasPrivat() {
    return Object.keys(this.context.vollmacht.vollmachten.maklervertrag.beratungsumfang_privat).length > 0;
  }  
  hasBeruflich() {
    return Object.keys(this.context.vollmacht.vollmachten.maklervertrag.beratungsumfang_beruflich).length > 0;
  }
  getNextPage(actual: string) {
    var found = false;
    for (let key in this.cfgBottomDrawer) {
      if (key == actual) {
        found = true;
      } else {
        if (found && this.cfgBottomDrawer[key].show) return key;
      }
    }
    return null;
  }

  constructor(public platform: Platform) {
    this.context.isPlatformDesktop = this.platform.is("desktop");
    this.context.isPlatformMobile = this.platform.is("mobileweb") || this.platform.is("mobile");
  }
}
